import React from 'react'
import Cleave from 'cleave.js/react'
import ReactGA from 'react-ga4'
import cx from 'classnames'
import { DateTime } from 'luxon'

import api from '../services/api'
import { toggle, intersect } from '../services/utils'

const validation = [
    {
        persons: (val) => val.length && val >= 6,
        date: (val) => {
            const date = DateTime.fromFormat(val, 'dd-MM-yyyy')
            const today = DateTime.now().startOf('day')
            const inTwoYears = today.plus({ years: 2 })
            return (
                val.length === 10 &&
                date &&
                date.valueOf() > today.valueOf() &&
                date.valueOf() < inTwoYears.valueOf()
            )
        },
        start: (val) => val.length > 0,
        end: (val, payload) => val.length > 0 && parseFloat(val) > parseFloat(payload.start),
        event: (val) => !!val,
    },
    {
        location: (val) => !!val,
        spaces: (val) => val.length > 0,
    },
    {
        options: (val, payload, response) => {
            if (!response) return true
            const arrangements = response.data.arrangements
                .filter((arrangement) => arrangement.enabled && arrangement.required)
                .map((arrangement) => arrangement.id)
            const options = response.data.options
                .filter(
                    (option) =>
                        arrangements.includes(option.arrangement_id) &&
                        !!intersect(option.spaces, payload.spaces).length,
                )
                .map((option) => option.id)
            const result = !options.length || !!intersect(options, val).length
            return result
        },
    },
    {
        mode: (val) => !!val,
        company: (val, payload) =>
            payload.mode === 'download' || !payload.business || val.length > 0,
        firstname: (val, payload) => payload.mode === 'download' || val.length > 0,
        lastname: (val, payload) => payload.mode === 'download' || val.length > 0,
        phone: (val, payload) => payload.mode === 'download' || val.length > 7,
        email: (val, payload) =>
            payload.mode === 'download' ||
            (val.length > 0 && val.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)),
    },
    {},
]
const times = Array(27)
    .fill(null)
    .map((v, k) => k / 2 + 9)
const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100
const taxCalculation = (num, percentage) => round((num / 100) * percentage)
const formatTime = (time) => {
    const hour = Math.floor(time)
    const rest = (time - hour) * 60
    return hour.toString().padStart(2, '0') + ':' + rest.toString().padStart(2, '0')
}
const addTax = (num, percentage, business) =>
    !business ? num + taxCalculation(num, percentage) : num
const formatPrice = (price) =>
    new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    }).format(price)
const formatNumber = (number) => new Intl.NumberFormat('nl-NL').format(number)
const handleClick = (e) => {
    e.target.parentElement.classList.toggle('expanded')
}

export default class App extends React.Component {
    state = {
        loading: true,
        step: null,
        response: null,
        touched: [],
        errors: [],
        payload: {
            business: true,
            code: '',
            persons: '',
            date: '',
            start: '',
            end: '',
            event: null,
            private: false,
            location: null,
            spaces: [],
            arrangements: [],
            options: [],
            extras: [],
            duration: 2,
            mode: 'send',
            company: '',
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            address: '',
            zipcode: '',
            city: '',
            note: '',
            comments: '',
        },
    }
    componentDidMount = () => {
        this.handleTouch()
        this.handleUpdate()
    }
    componentDidUpdate = (props) => {
        if (this.props.location !== props.location) this.handleRouteChange()
    }
    handleRouteChange = () => {
        ReactGA.send('pageview')
        const step =
            this.state.response.data.steps
                .map((step) => step.url)
                .indexOf(this.props.location.pathname) + 1
        if (!step) {
            this.props.history.replace(this.state.response.data.steps[0].url)
        } else {
            this.setState({ step }, () => {
                this.handleUpdate()
            })
        }
    }
    handleUpdate = () => {
        this.setState({ loading: true })
        api.update(this.state.step, this.state.payload).then((response) => {
            this.setState({ response: response.data, payload: response.data.payload })
            if (this.state.step !== response.data.status.step) {
                this.props.history.replace(
                    response.data.data.steps[
                        !response.data.status.step
                            ? response.data.status.step
                            : response.data.status.step - 1
                    ].url,
                )
            } else {
                this.setState({ loading: false })
            }
        })
    }
    handlePrevious = (e) => {
        this.props.history.push(this.state.response.data.steps[this.state.step - 2].url)
    }
    handleNext = (e) => {
        this.handleTouch()
        this.setState(
            {
                touched: Object.keys(validation[this.state.step - 1])
                    .concat(...this.state.touched)
                    .filter((value, index, self) => self.indexOf(value) === index),
            },
            () => {
                if (
                    ![...this.state.errors].filter((error) =>
                        Object.keys(validation[this.state.step - 1]).includes(error),
                    ).length
                ) {
                    if (this.state.step === 4 && this.state.payload.mode === 'download') {
                        this.setState({ loading: true })
                        api.download(this.state.payload).then((response) => {
                            const link = document.createElement('a')
                            link.href = URL.createObjectURL(response.data)
                            link.download = 'offerte.pdf'
                            link.click()
                            this.setState({ loading: false })
                        })
                    } else {
                        this.props.history.push(this.state.response.data.steps[this.state.step].url)
                    }
                } else {
                    document.querySelector('.form.active').scrollTop = 0
                }
            },
        )
    }
    handleTouch = (key) => {
        const touched = key
            ? [...this.state.touched, key].filter(
                  (value, index, self) => self.indexOf(value) === index,
              )
            : [...this.state.touched]
        let errors = []
        validation.forEach((step) =>
            Object.keys(step).forEach((key) => {
                if (!step[key](this.state.payload[key], this.state.payload, this.state.response))
                    errors.push(key)
            }),
        )
        this.setState({ touched, errors })
    }
    handleTextChange = (e, key) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                [key]: e.target.value,
            },
        }))
    }
    handlePhoneChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                phone: e.target.value.replace(/[^0-9/+]/g, ''),
            },
        }))
    }
    handleEmailChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                email: e.target.value.replace(/ /g, '').toLowerCase(),
            },
        }))
    }
    handleModeChange = (e, mode) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                company: '',
                address: '',
                zipcode: '',
                city: '',
                note: '',
                mode,
            },
        }))
    }
    handleTypeChange = (e, business) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                company: '',
                address: '',
                zipcode: '',
                city: '',
                note: '',
                business,
            },
        }))
    }
    handleCodeChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                code: e.target.value,
                location: null,
                spaces: [],
                arrangements: [],
                options: [],
                extras: [],
                duration: 2,
            },
        }))
    }
    handlePersonsChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                persons: e.target.value.replace(/\D/g, ''),
                location: null,
                spaces: [],
                arrangements: [],
                options: [],
                extras: [],
                duration: 2,
            },
        }))
    }
    handleDateChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                date: e.target.value,
            },
        }))
    }
    handleStartChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                start: e.target.value,
            },
        }))
    }
    handleEndChange = (e) => {
        this.setState((state) => ({
            payload: {
                ...state.payload,
                end: e.target.value,
            },
        }))
    }
    handleEventChange = (e, event) => {
        this.setState(
            (state) => ({
                payload: {
                    ...state.payload,
                    event: state.payload.event === event.id ? null : event.id,
                    location: null,
                    spaces: [],
                    private: !!event.private,
                    arrangements: [],
                    options: [],
                    extras: [],
                    duration: 2,
                },
            }),
            () => this.handleTouch('event'),
        )
    }
    handlePrivateChange = (e, event) => {
        e.stopPropagation()
        if (event.id !== this.state.payload.event) this.handleEventChange(e, event)
        this.setState((state) => ({
            payload: {
                ...state.payload,
                private: state.payload.event === event.id ? !state.payload.private : true,
            },
        }))
    }
    handleLocationChange = (e, location) => {
        this.setState(
            (state) => ({
                payload: {
                    ...state.payload,
                    location: state.payload.location === location.id ? null : location.id,
                    spaces: [],
                },
            }),
            () => this.handleTouch('location'),
        )
    }
    handleSpacesChange = (e, space, primary) => {
        const origin = this.state.payload.spaces
        let dest = toggle(origin, space.id)
        if (primary && origin.length) dest = []
        const arrangements = this.state.response.data.spaces
            .filter((space) => dest.includes(space.id))
            .map((space) => space.arrangements)
            .flat()
        const auto = this.state.response.data.arrangements
            .filter((arrangement) => arrangements.includes(arrangement.id))
            .filter((arrangement) => arrangement.auto)
            .map((arrangement) => arrangement.id)
        this.setState(
            (state) => ({
                payload: {
                    ...state.payload,
                    spaces: dest,
                    arrangements: auto,
                    options: [],
                    extras: [],
                    duration: 2,
                },
            }),
            () => this.handleTouch('spaces'),
        )
    }
    handleOptionChange = (e, option) => {
        e.stopPropagation()
        const og = this.state.response.data.options
            .filter(
                (item) =>
                    item.arrangement_id === option.arrangement_id && !option.arrangement.multiple,
            )
            .map((item) => item.id)
        const origin = this.state.payload.options.includes(option.id)
            ? [...this.state.payload.options]
            : [...this.state.payload.options.filter((item) => !og.includes(item))]
        const dest = toggle(origin, option.id)
        const eg = this.state.response.data.extras
            .filter((item) => item.arrangement_id === option.arrangement_id)
            .map((item) => item.id)
        const extras = [...this.state.payload.extras.filter((item) => !eg.includes(item))]
        const arr = this.state.response.data.arrangements.find(
            (item) => item.id === option.arrangement_id,
        )
        const duration = !arr.duration ? this.state.payload.duration : 2
        this.setState(
            (state) => ({
                payload: { ...state.payload, options: dest, extras, duration },
            }),
            () => this.handleTouch('options'),
        )
    }
    handleExtraChange = (e, extra, option) => {
        e.stopPropagation()
        if (!this.state.payload.options.includes(option.id)) this.handleOptionChange(e, option)
        const group = this.state.response.data.extras
            .filter((item) => item.arrangement_id === extra.arrangement_id)
            .map((item) => item.id)
        const origin = this.state.payload.options.includes(option.id)
            ? [...this.state.payload.extras]
            : [...this.state.payload.extras.filter((item) => !group.includes(item))]
        const dest = toggle(origin, extra.id)
        this.setState((state) => ({
            payload: { ...state.payload, extras: dest },
        }))
    }
    handleDurationChange = (e, duration, option) => {
        e.stopPropagation()
        if (!this.state.payload.options.includes(option.id)) this.handleOptionChange(e, option)
        this.setState((state) => ({
            payload: { ...state.payload, duration },
        }))
    }
    isPrice = () => {
        let result = {
            subtotal: 0,
            discount: 0,
            tax: 0,
            total: 0,
        }
        if (!this.state.response) return result
        const persons = this.state.payload.persons.length ? this.state.payload.persons * 1 : 0
        const spaces = this.state.response.data.spaces.filter((item) =>
            this.state.payload.spaces.includes(item.id),
        )
        const duration = this.state.payload.duration
        const arrangements = this.state.response.data.arrangements
        const options = this.state.response.data.options.filter((item) =>
            this.state.payload.options.includes(item.id),
        )
        const extras = this.state.response.data.extras.filter((item) =>
            this.state.payload.extras.includes(item.id),
        )
        if (spaces.length) {
            let spacesDiscount = 0
            let spacesPrice = spaces
                .map(
                    (space) =>
                        space.price * this.state.response.status.time.length -
                        (this.state.response.status.time.length >= 8 ? space.discount : 0),
                )
                .reduce((a, b) => {
                    return a + b
                }, 0)
            let spacesTax = spaces
                .map((space) =>
                    taxCalculation(
                        space.price * this.state.response.status.time.length -
                            (this.state.response.status.time.length >= 8 ? space.discount : 0),
                        space.tax_percentage,
                    ),
                )
                .reduce((a, b) => {
                    return a + b
                }, 0)
            if (this.state.response.status.discount) {
                spacesDiscount =
                    (spacesPrice / 100) * this.state.response.status.discount.percentage
                spacesTax =
                    (spacesTax / 100) * (100 - this.state.response.status.discount.percentage)
            }
            result.discount += spacesDiscount
            result.subtotal += spacesPrice
            result.tax += spacesTax
        }
        if (options.length) {
            result.subtotal += options
                .map((item) => {
                    const p = arrangements.find((a) => a.id === item.arrangement_id).duration
                    const pt = p
                        ? (item.start + item.price * duration) * persons
                        : item.price * persons
                    return pt
                })
                .reduce((a, b) => a + b, 0)
            result.tax += options
                .map((item) => {
                    const p = arrangements.find((a) => a.id === item.arrangement_id).duration
                    const pt = p
                        ? (item.start + item.price * duration) * persons
                        : item.price * persons
                    return taxCalculation(pt, item.tax_percentage)
                })
                .reduce((a, b) => a + b, 0)
        }
        if (extras.length) {
            result.subtotal += extras.map((item) => item.price * persons).reduce((a, b) => a + b, 0)
            result.tax += extras
                .map((item) => taxCalculation(item.price * persons, item.tax_percentage))
                .reduce((a, b) => a + b, 0)
        }
        result.total = result.subtotal - result.discount + result.tax
        return result
    }
    render() {
        const price = this.isPrice()
        return (
            !!this.state.response && (
                <>
                    <div className="canvas">
                        <div
                            className={cx('form', {
                                active: this.state.response.status.step === 1,
                            })}>
                            <div className="step">
                                <h2>1. {this.state.response.data.steps[0].name}</h2>
                                <div className="row">
                                    <p className="large">
                                        {this.state.response.data.steps[0].description}
                                    </p>
                                </div>
                                <div className="row">
                                    <h3 className="req">
                                        {this.state.response.data.questions.type}
                                    </h3>
                                    <div className="content">
                                        <ul>
                                            {[
                                                { value: true, label: 'Zakelijk' },
                                                { value: false, label: 'Privé' },
                                            ].map((type, key) => (
                                                <li
                                                    key={key}
                                                    onClick={(e) =>
                                                        this.handleTypeChange(e, type.value)
                                                    }>
                                                    <input
                                                        type="radio"
                                                        checked={
                                                            this.state.payload.business ===
                                                            type.value
                                                        }
                                                        disabled
                                                    />
                                                    {type.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            this.state.touched.includes('persons') &&
                                            this.state.errors.includes('persons'),
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.persons}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('persons') &&
                                            this.state.errors.includes('persons') && (
                                                <li>
                                                    Het aantal personen is leeg of minder dan 6.
                                                    Voor minder dan 6 personen kunt u gebruik maken
                                                    van de reserveringstool op houseofbird.nl.
                                                </li>
                                            )}
                                    </ul>
                                    <div className="content">
                                        <input
                                            type="text"
                                            placeholder="6-150"
                                            value={this.state.payload.persons}
                                            onChange={this.handlePersonsChange}
                                            onBlur={(e) => this.handleTouch('persons')}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            this.state.touched.includes('date') &&
                                            this.state.errors.includes('date'),
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.date}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('date') &&
                                            this.state.errors.includes('date') && (
                                                <li>
                                                    Datum is leeg, ligt in het verleden, te ver in
                                                    de toekomst of heeft niet het juiste format
                                                    (dd-mm-yyyy)
                                                </li>
                                            )}
                                    </ul>
                                    <div className="content">
                                        <Cleave
                                            value={this.state.payload.date}
                                            placeholder="dd-mm-jjjj"
                                            options={{
                                                date: true,
                                                delimiter: '-',
                                                datePattern: ['d', 'm', 'Y'],
                                            }}
                                            onChange={(e) => this.handleDateChange(e)}
                                            onBlur={(e) => this.handleTouch('date')}
                                            className="form-field"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            (this.state.touched.includes('start') &&
                                                this.state.errors.includes('start')) ||
                                            (this.state.touched.includes('end') &&
                                                this.state.errors.includes('end')),
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.start_end}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('start') &&
                                            this.state.errors.includes('start') && (
                                                <li>Starttijd is leeg</li>
                                            )}
                                        {this.state.touched.includes('end') &&
                                            this.state.errors.includes('end') && (
                                                <li>
                                                    Eindtijd is leeg of niet later dan de starttijd
                                                </li>
                                            )}
                                    </ul>
                                    <div className="content">
                                        <select
                                            className={cx({
                                                placeholder: !this.state.payload.start.length,
                                            })}
                                            value={this.state.payload.start}
                                            onChange={this.handleStartChange}
                                            onBlur={(e) => this.handleTouch('start')}>
                                            <option value="">Starttijd</option>
                                            {times.map((time, key) => (
                                                <option key={key} value={time}>
                                                    {formatTime(time)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="content">
                                        <select
                                            className={cx({
                                                placeholder: !this.state.payload.end.length,
                                            })}
                                            value={this.state.payload.end}
                                            onChange={this.handleEndChange}
                                            onBlur={(e) => this.handleTouch('end')}>
                                            <option value="">Eindtijd</option>
                                            {times.map((time, key) => (
                                                <option key={key} value={time}>
                                                    {formatTime(time)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3>{this.state.response.data.questions.code}</h3>
                                    <div className="content">
                                        <input
                                            type="text"
                                            placeholder=""
                                            value={this.state.payload.code}
                                            onChange={this.handleCodeChange}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            this.state.touched.includes('event') &&
                                            this.state.errors.includes('event'),
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.event}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('event') &&
                                            this.state.errors.includes('event') && (
                                                <li>Er is geen soort bijeenkomst geselecteerd</li>
                                            )}
                                    </ul>
                                    <div className="content fix">
                                        <div className="grid">
                                            {this.state.response.data.events.map((event, key) => (
                                                <div
                                                    key={key}
                                                    className={cx('item', {
                                                        active:
                                                            this.state.payload.event === event.id,
                                                    })}>
                                                    <div
                                                        className="box"
                                                        onClick={(e) =>
                                                            this.handleEventChange(e, event)
                                                        }>
                                                        <div className="image">
                                                            <div className="holder">
                                                                {event.image && (
                                                                    <img
                                                                        src={event.image}
                                                                        alt={event.name}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="check"></div>
                                                        </div>
                                                        <div className="inner">
                                                            <div className="title">
                                                                <h4>{event.name}</h4>
                                                            </div>
                                                            <p>{event.description}</p>
                                                            {event.private === null && (
                                                                <ul>
                                                                    <li
                                                                        onClick={(e) =>
                                                                            this.handlePrivateChange(
                                                                                e,
                                                                                event,
                                                                            )
                                                                        }>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                this.state.payload
                                                                                    .event ===
                                                                                    event.id &&
                                                                                this.state.payload
                                                                                    .private
                                                                            }
                                                                            disabled
                                                                        />
                                                                        Privé ruimte huren
                                                                    </li>
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={cx('form', {
                                active: this.state.response.status.step === 2,
                            })}>
                            <div className="step">
                                <h2>2. {this.state.response.data.steps[1].name}</h2>
                                <div className="row">
                                    <p className="large">
                                        {this.state.response.data.steps[1].description}
                                    </p>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            this.state.touched.includes('location') &&
                                            this.state.errors.includes('location'),
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.location}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('location') &&
                                            this.state.errors.includes('location') && (
                                                <li>Er is geen locatie geselecteerd</li>
                                            )}
                                    </ul>
                                    <div className="content">
                                        <div className="grid">
                                            {this.state.response.data.locations.map(
                                                (location, key) => (
                                                    <div
                                                        key={key}
                                                        className={cx('item', {
                                                            active:
                                                                this.state.payload.location ===
                                                                location.id,
                                                        })}>
                                                        <div
                                                            className="box"
                                                            onClick={(e) =>
                                                                this.handleLocationChange(
                                                                    e,
                                                                    location,
                                                                )
                                                            }>
                                                            <div className="image">
                                                                <div className="holder">
                                                                    {location.image && (
                                                                        <img
                                                                            src={location.image}
                                                                            alt={location.name}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="check"></div>
                                                            </div>
                                                            <div className="inner">
                                                                <div className="title">
                                                                    <h4>{location.name}</h4>
                                                                </div>
                                                                <p>{location.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            this.state.touched.includes('spaces') &&
                                            this.state.errors.includes('spaces') &&
                                            this.state.payload.location,
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.space_enabled}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('spaces') &&
                                            this.state.errors.includes('spaces') && (
                                                <li>Er is geen ruimte geselecteerd</li>
                                            )}
                                    </ul>
                                    {this.state.payload.location ? (
                                        <div className="content">
                                            <div className="grid">
                                                {this.state.response.data.spaces
                                                    .filter(
                                                        (space) =>
                                                            space.location_id ===
                                                            this.state.payload.location,
                                                    )
                                                    .sort((a, b) => {
                                                        const ar =
                                                            !a.primary ||
                                                            a.disabled ||
                                                            (this.state.payload.spaces.length &&
                                                                this.state.payload.spaces[0] !==
                                                                    a.id)
                                                        const br =
                                                            !b.primary ||
                                                            b.disabled ||
                                                            (this.state.payload.spaces.length &&
                                                                this.state.payload.spaces[0] !==
                                                                    b.id)
                                                        return ar - br
                                                    })
                                                    .map((space, key) => (
                                                        <div
                                                            key={key}
                                                            className={cx('item', {
                                                                active:
                                                                    this.state.payload.spaces.includes(
                                                                        space.id,
                                                                    ) &&
                                                                    this.state.payload.spaces[0] ===
                                                                        space.id,
                                                                disabled:
                                                                    !space.primary ||
                                                                    space.disabled ||
                                                                    (this.state.payload.spaces
                                                                        .length &&
                                                                        this.state.payload
                                                                            .spaces[0] !==
                                                                            space.id),
                                                            })}>
                                                            <div
                                                                className="box"
                                                                onClick={(e) =>
                                                                    this.handleSpacesChange(
                                                                        e,
                                                                        space,
                                                                        true,
                                                                    )
                                                                }>
                                                                <div className="image">
                                                                    <div className="holder">
                                                                        {space.image && (
                                                                            <img
                                                                                src={space.image}
                                                                                alt={space.name}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="check"></div>
                                                                </div>
                                                                <div className="inner">
                                                                    <div className="title">
                                                                        <h4>
                                                                            {space.name} (
                                                                            {
                                                                                this.state.response.data.locations.find(
                                                                                    (location) =>
                                                                                        location.id ===
                                                                                        space.location_id,
                                                                                ).name
                                                                            }
                                                                            )
                                                                        </h4>
                                                                        {!!space.price && (
                                                                            <span>
                                                                                {formatPrice(
                                                                                    addTax(
                                                                                        space.price *
                                                                                            this
                                                                                                .state
                                                                                                .response
                                                                                                .status
                                                                                                .time
                                                                                                .length -
                                                                                            (this
                                                                                                .state
                                                                                                .response
                                                                                                .status
                                                                                                .time
                                                                                                .length >=
                                                                                            8
                                                                                                ? space.discount
                                                                                                : 0),
                                                                                        space.tax_percentage,
                                                                                        this.state
                                                                                            .response
                                                                                            .payload
                                                                                            .business,
                                                                                    ),
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <p>{space.description}</p>
                                                                    <ul className="details">
                                                                        {space.persons_min > 1 && (
                                                                            <li className="persons">
                                                                                Min.{' '}
                                                                                {space.persons_min}
                                                                            </li>
                                                                        )}
                                                                        {space.persons_max > 1 && (
                                                                            <li className="persons">
                                                                                Max.{' '}
                                                                                {space.persons_max}
                                                                            </li>
                                                                        )}
                                                                        {!!space.size_inside && (
                                                                            <li className="size">
                                                                                {space.size_inside}{' '}
                                                                                m2 binnen
                                                                            </li>
                                                                        )}
                                                                        {!!space.size_outside && (
                                                                            <li className="size">
                                                                                {space.size_outside}{' '}
                                                                                m2 buiten
                                                                            </li>
                                                                        )}
                                                                        {!!space.link && (
                                                                            <li className="link">
                                                                                <a
                                                                                    onClick={(e) =>
                                                                                        e.stopPropagation()
                                                                                    }
                                                                                    href={
                                                                                        space.link
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer">
                                                                                    Meer informatie
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="hint">
                                            Je dient eerst een van onze locaties te kiezen voordat
                                            je een ruimte kunt selecteren.
                                        </p>
                                    )}
                                </div>
                                <div className="row expandable" onClick={handleClick}>
                                    <h3>{this.state.response.data.questions.space_extra}</h3>
                                    {this.state.payload.spaces.length ? (
                                        <div className="content">
                                            <div className="grid">
                                                {this.state.response.data.spaces
                                                    .filter(
                                                        (space) =>
                                                            space.location_id ===
                                                                this.state.payload.location &&
                                                            this.state.payload.spaces[0] !==
                                                                space.id,
                                                    )
                                                    .sort((a, b) => {
                                                        const ar = !a.secondary || a.disabled
                                                        const br = !b.secondary || b.disabled
                                                        return ar - br
                                                    })
                                                    .map((space, key) => (
                                                        <div
                                                            key={key}
                                                            className={cx('item', {
                                                                active: this.state.payload.spaces.includes(
                                                                    space.id,
                                                                ),
                                                                disabled:
                                                                    !space.secondary ||
                                                                    space.disabled,
                                                            })}>
                                                            <div
                                                                className="box"
                                                                onClick={(e) =>
                                                                    this.handleSpacesChange(
                                                                        e,
                                                                        space,
                                                                        false,
                                                                    )
                                                                }>
                                                                <div className="image">
                                                                    <div className="holder">
                                                                        {space.image && (
                                                                            <img
                                                                                src={space.image}
                                                                                alt={space.name}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="check"></div>
                                                                </div>
                                                                <div className="inner">
                                                                    <div className="title">
                                                                        <h4>
                                                                            {space.name} (
                                                                            {
                                                                                this.state.response.data.locations.find(
                                                                                    (location) =>
                                                                                        location.id ===
                                                                                        space.location_id,
                                                                                ).name
                                                                            }
                                                                            )
                                                                        </h4>
                                                                        {!!space.price && (
                                                                            <span>
                                                                                {formatPrice(
                                                                                    addTax(
                                                                                        space.price *
                                                                                            this
                                                                                                .state
                                                                                                .response
                                                                                                .status
                                                                                                .time
                                                                                                .length -
                                                                                            (this
                                                                                                .state
                                                                                                .response
                                                                                                .status
                                                                                                .time
                                                                                                .length >=
                                                                                            8
                                                                                                ? space.discount
                                                                                                : 0),
                                                                                        space.tax_percentage,
                                                                                        this.state
                                                                                            .response
                                                                                            .payload
                                                                                            .business,
                                                                                    ),
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <p>{space.description}</p>
                                                                    <ul className="details">
                                                                        {space.persons_min > 1 && (
                                                                            <li className="persons">
                                                                                Min.{' '}
                                                                                {space.persons_min}
                                                                            </li>
                                                                        )}
                                                                        {space.persons_max > 1 && (
                                                                            <li className="persons">
                                                                                Max.{' '}
                                                                                {space.persons_max}
                                                                            </li>
                                                                        )}
                                                                        {!!space.size_inside && (
                                                                            <li className="size">
                                                                                {space.size_inside}{' '}
                                                                                m2 binnen
                                                                            </li>
                                                                        )}
                                                                        {!!space.size_outside && (
                                                                            <li className="size">
                                                                                {space.size_outside}{' '}
                                                                                m2 buiten
                                                                            </li>
                                                                        )}
                                                                        {!!space.link && (
                                                                            <li className="link">
                                                                                <a
                                                                                    onClick={(e) =>
                                                                                        e.stopPropagation()
                                                                                    }
                                                                                    href={
                                                                                        space.link
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer">
                                                                                    Meer informatie
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="hint">
                                            Je dient eerst een primaire ruimte te kiezen voordat je
                                            eventuele extra ruimte's kunt selecteren.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={cx('form', {
                                active: this.state.response.status.step === 3,
                            })}>
                            <div className="step">
                                <h2>3. {this.state.response.data.steps[2].name}</h2>
                                <div className="row">
                                    <p className="large">
                                        {this.state.response.data.steps[2].description}
                                    </p>
                                </div>
                                {this.state.response.data.arrangements
                                    .filter(
                                        (arrangement) =>
                                            arrangement.enabled &&
                                            this.state.payload.arrangements.includes(
                                                arrangement.id,
                                            ),
                                    )
                                    .map((arrangement, key) => (
                                        <React.Fragment key={key}>
                                            {!!this.state.response.data.options.filter(
                                                (option) =>
                                                    option.arrangement_id === arrangement.id &&
                                                    (option.spaces === null ||
                                                        !!option.spaces.filter((space) =>
                                                            this.state.payload.spaces.includes(
                                                                space,
                                                            ),
                                                        ).length),
                                            ).length && (
                                                <div
                                                    className={cx(['row', 'expandable'], {
                                                        expanded: arrangement.expanded,
                                                        errors:
                                                            this.state.touched.includes(
                                                                'options',
                                                            ) &&
                                                            this.state.errors.includes('options') &&
                                                            this.state.payload.spaces.length &&
                                                            arrangement.required,
                                                    })}
                                                    onClick={handleClick}>
                                                    <h3
                                                        className={cx({
                                                            req: arrangement.required,
                                                        })}>
                                                        <span>Selecteer jouw</span>{' '}
                                                        {arrangement.name} (
                                                        {
                                                            this.state.response.data.options.filter(
                                                                (option) =>
                                                                    option.enabled &&
                                                                    option.arrangement_id ===
                                                                        arrangement.id &&
                                                                    (option.spaces === null ||
                                                                        !!option.spaces.filter(
                                                                            (space) =>
                                                                                this.state.payload.spaces.includes(
                                                                                    space,
                                                                                ),
                                                                        ).length),
                                                            ).length
                                                        }
                                                        )
                                                    </h3>
                                                    <p className="hint">
                                                        {arrangement.description}
                                                    </p>
                                                    <div className="content">
                                                        <div className="grid">
                                                            {this.state.response.data.options
                                                                .filter(
                                                                    (option) =>
                                                                        option.enabled &&
                                                                        option.arrangement_id ===
                                                                            arrangement.id &&
                                                                        (option.spaces === null ||
                                                                            !!option.spaces.filter(
                                                                                (space) =>
                                                                                    this.state.payload.spaces.includes(
                                                                                        space,
                                                                                    ),
                                                                            ).length),
                                                                )
                                                                .map((option, key) => (
                                                                    <div
                                                                        key={key}
                                                                        className={cx('item', {
                                                                            active: this.state.payload.options.includes(
                                                                                option.id,
                                                                            ),
                                                                        })}>
                                                                        <div
                                                                            className="box"
                                                                            onClick={(e) =>
                                                                                this.handleOptionChange(
                                                                                    e,
                                                                                    option,
                                                                                )
                                                                            }>
                                                                            <div className="image">
                                                                                <div className="holder">
                                                                                    {option.image && (
                                                                                        <img
                                                                                            src={
                                                                                                option.image
                                                                                            }
                                                                                            alt={
                                                                                                option.name
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div className="check"></div>
                                                                            </div>
                                                                            <div className="inner">
                                                                                <div className="title">
                                                                                    <h4>
                                                                                        {
                                                                                            option.name
                                                                                        }
                                                                                    </h4>
                                                                                    {!arrangement.duration && (
                                                                                        <span>
                                                                                            {formatPrice(
                                                                                                addTax(
                                                                                                    option.price,
                                                                                                    option.tax_percentage,
                                                                                                    this
                                                                                                        .state
                                                                                                        .response
                                                                                                        .payload
                                                                                                        .business,
                                                                                                ),
                                                                                            )}{' '}
                                                                                            p.p.
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <p>
                                                                                    {
                                                                                        option.description
                                                                                    }
                                                                                </p>
                                                                                {!!option.persons && (
                                                                                    <ul className="details">
                                                                                        <li className="persons">
                                                                                            Min.{' '}
                                                                                            {
                                                                                                option.persons
                                                                                            }
                                                                                        </li>
                                                                                    </ul>
                                                                                )}
                                                                                {arrangement.duration && (
                                                                                    <ul>
                                                                                        {[
                                                                                            2, 2.5,
                                                                                            3, 4, 5,
                                                                                        ].map(
                                                                                            (
                                                                                                duration,
                                                                                                key,
                                                                                            ) => (
                                                                                                <li
                                                                                                    key={
                                                                                                        key
                                                                                                    }
                                                                                                    onClick={(
                                                                                                        e,
                                                                                                    ) =>
                                                                                                        this.handleDurationChange(
                                                                                                            e,
                                                                                                            duration,
                                                                                                            option,
                                                                                                        )
                                                                                                    }>
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        checked={
                                                                                                            this.state.payload.options.includes(
                                                                                                                option.id,
                                                                                                            ) &&
                                                                                                            this
                                                                                                                .state
                                                                                                                .payload
                                                                                                                .duration ===
                                                                                                                duration
                                                                                                        }
                                                                                                        disabled
                                                                                                    />
                                                                                                    {formatNumber(
                                                                                                        duration,
                                                                                                    )}{' '}
                                                                                                    uur{' '}
                                                                                                    <span className="price">
                                                                                                        {formatPrice(
                                                                                                            addTax(
                                                                                                                option.start +
                                                                                                                    option.price *
                                                                                                                        duration,
                                                                                                                option.tax_percentage,
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .response
                                                                                                                    .payload
                                                                                                                    .business,
                                                                                                            ),
                                                                                                        )}{' '}
                                                                                                        p.p.
                                                                                                    </span>
                                                                                                </li>
                                                                                            ),
                                                                                        )}
                                                                                    </ul>
                                                                                )}
                                                                                {!!option.extras
                                                                                    .length && (
                                                                                    <ul>
                                                                                        {option.extras
                                                                                            .map(
                                                                                                (
                                                                                                    id,
                                                                                                ) =>
                                                                                                    this.state.response.data.extras.find(
                                                                                                        (
                                                                                                            item,
                                                                                                        ) =>
                                                                                                            item.id ===
                                                                                                            id,
                                                                                                    ),
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    extra,
                                                                                                    key,
                                                                                                ) => (
                                                                                                    <li
                                                                                                        key={
                                                                                                            key
                                                                                                        }
                                                                                                        onClick={(
                                                                                                            e,
                                                                                                        ) =>
                                                                                                            this.handleExtraChange(
                                                                                                                e,
                                                                                                                extra,
                                                                                                                option,
                                                                                                            )
                                                                                                        }>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            checked={
                                                                                                                this.state.payload.options.includes(
                                                                                                                    option.id,
                                                                                                                ) &&
                                                                                                                this.state.payload.extras.includes(
                                                                                                                    extra.id,
                                                                                                                )
                                                                                                            }
                                                                                                            disabled
                                                                                                        />
                                                                                                        {
                                                                                                            extra.name
                                                                                                        }{' '}
                                                                                                        <span className="price">
                                                                                                            {formatPrice(
                                                                                                                addTax(
                                                                                                                    extra.price,
                                                                                                                    extra.tax_percentage,
                                                                                                                    this
                                                                                                                        .state
                                                                                                                        .response
                                                                                                                        .payload
                                                                                                                        .business,
                                                                                                                ),
                                                                                                            )}{' '}
                                                                                                            p.p.
                                                                                                        </span>
                                                                                                    </li>
                                                                                                ),
                                                                                            )}
                                                                                    </ul>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                            </div>
                        </div>
                        <div
                            className={cx('form', {
                                active: this.state.response.status.step === 4,
                            })}>
                            <div className="step">
                                <h2>4. {this.state.response.data.steps[3].name}</h2>
                                <div className="row">
                                    <p className="large">
                                        {this.state.response.data.steps[3].description}
                                    </p>
                                </div>
                                <div className="row">
                                    <h3 className="req">Type</h3>
                                    <div className="content">
                                        <ul>
                                            {[
                                                { value: true, label: 'Zakelijk' },
                                                { value: false, label: 'Privé' },
                                            ].map((type, key) => (
                                                <li
                                                    key={key}
                                                    onClick={(e) =>
                                                        this.handleTypeChange(e, type.value)
                                                    }>
                                                    <input
                                                        type="radio"
                                                        checked={
                                                            this.state.payload.business ===
                                                            type.value
                                                        }
                                                        disabled
                                                    />
                                                    {type.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className={cx('row', {
                                        errors:
                                            this.state.touched.includes('mode') &&
                                            this.state.errors.includes('mode'),
                                    })}>
                                    <h3 className="req">
                                        {this.state.response.data.questions.quote}
                                    </h3>
                                    <ul className="errors">
                                        {this.state.touched.includes('mode') &&
                                            this.state.errors.includes('mode') && (
                                                <li>Het veld is niet ingevuld</li>
                                            )}
                                    </ul>
                                    <div className="content">
                                        <ul>
                                            {[
                                                { value: 'send', label: 'Aanvraag versturen' },
                                                { value: 'download', label: 'Offerte downloaden' },
                                            ].map((mode, key) => (
                                                <li
                                                    key={key}
                                                    onClick={(e) =>
                                                        this.handleModeChange(e, mode.value)
                                                    }>
                                                    <input
                                                        type="radio"
                                                        checked={
                                                            this.state.payload.mode === mode.value
                                                        }
                                                        disabled
                                                    />
                                                    {mode.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {this.state.payload.mode === 'send' && (
                                    <>
                                        {!!this.state.payload.business && (
                                            <div
                                                className={cx('row', {
                                                    errors:
                                                        this.state.touched.includes('company') &&
                                                        this.state.errors.includes('company'),
                                                })}>
                                                <h3 className="req">
                                                    {this.state.response.data.questions.company}
                                                </h3>
                                                <ul className="errors">
                                                    {this.state.touched.includes('company') &&
                                                        this.state.errors.includes('company') && (
                                                            <li>Bedrijf is leeg</li>
                                                        )}
                                                </ul>
                                                <div className="content">
                                                    <input
                                                        type="text"
                                                        placeholder="Bedrijf"
                                                        value={this.state.payload.company}
                                                        onChange={(e) =>
                                                            this.handleTextChange(e, 'company')
                                                        }
                                                        onBlur={(e) => this.handleTouch('company')}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className={cx('row', {
                                                errors:
                                                    (this.state.touched.includes('firstname') &&
                                                        this.state.errors.includes('firstname')) ||
                                                    (this.state.touched.includes('lastname') &&
                                                        this.state.errors.includes('lastname')),
                                            })}>
                                            {!!this.state.payload.business && (
                                                <h3 className="req">
                                                    {
                                                        this.state.response.data.questions
                                                            .name_business
                                                    }
                                                </h3>
                                            )}
                                            {!this.state.payload.business && (
                                                <h3 className="req">
                                                    {
                                                        this.state.response.data.questions
                                                            .name_private
                                                    }
                                                </h3>
                                            )}
                                            <ul className="errors">
                                                {this.state.touched.includes('firstname') &&
                                                    this.state.errors.includes('firstname') && (
                                                        <li>Voornaam is leeg</li>
                                                    )}
                                                {this.state.touched.includes('lastname') &&
                                                    this.state.errors.includes('lastname') && (
                                                        <li>Achternaam is leeg</li>
                                                    )}
                                            </ul>
                                            <div className="content">
                                                <input
                                                    type="text"
                                                    placeholder="Voornaam"
                                                    value={this.state.payload.firstname}
                                                    onChange={(e) =>
                                                        this.handleTextChange(e, 'firstname')
                                                    }
                                                    onBlur={(e) => this.handleTouch('firstname')}
                                                />
                                            </div>
                                            <div className="content">
                                                <input
                                                    type="text"
                                                    placeholder="Achternaam"
                                                    value={this.state.payload.lastname}
                                                    onChange={(e) =>
                                                        this.handleTextChange(e, 'lastname')
                                                    }
                                                    onBlur={(e) => this.handleTouch('lastname')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={cx('row', {
                                                errors:
                                                    (this.state.touched.includes('email') &&
                                                        this.state.errors.includes('email')) ||
                                                    (this.state.touched.includes('phone') &&
                                                        this.state.errors.includes('phone')),
                                            })}>
                                            <h3 className="req">
                                                {this.state.response.data.questions.contact}
                                            </h3>
                                            <ul className="errors">
                                                {this.state.touched.includes('email') &&
                                                    this.state.errors.includes('email') && (
                                                        <li>E-mailadres is leeg of is ongeldig</li>
                                                    )}
                                                {this.state.touched.includes('phone') &&
                                                    this.state.errors.includes('phone') && (
                                                        <li>
                                                            Telefoonnummer is leeg of is ongeldig
                                                        </li>
                                                    )}
                                            </ul>
                                            <div className="content">
                                                <input
                                                    type="text"
                                                    placeholder="E-mailadres"
                                                    value={this.state.payload.email}
                                                    onChange={(e) => this.handleEmailChange(e)}
                                                    onBlur={(e) => this.handleTouch('email')}
                                                />
                                            </div>
                                            <div className="content">
                                                <input
                                                    type="text"
                                                    placeholder="Telefoonnummer"
                                                    value={this.state.payload.phone}
                                                    onChange={(e) => this.handlePhoneChange(e)}
                                                    onBlur={(e) => this.handleTouch('phone')}
                                                />
                                            </div>
                                        </div>
                                        {!!this.state.payload.business && (
                                            <div className="row">
                                                <h3>
                                                    {this.state.response.data.questions.invoice}
                                                </h3>
                                                <div className="content">
                                                    <input
                                                        type="text"
                                                        placeholder="Adres"
                                                        value={this.state.payload.address}
                                                        onChange={(e) =>
                                                            this.handleTextChange(e, 'address')
                                                        }
                                                    />
                                                </div>
                                                <div className="content">
                                                    <input
                                                        type="text"
                                                        placeholder="Postcode"
                                                        value={this.state.payload.zipcode}
                                                        onChange={(e) =>
                                                            this.handleTextChange(e, 'zipcode')
                                                        }
                                                    />
                                                </div>
                                                <div className="content">
                                                    <input
                                                        type="text"
                                                        placeholder="Plaats"
                                                        value={this.state.payload.city}
                                                        onChange={(e) =>
                                                            this.handleTextChange(e, 'city')
                                                        }
                                                    />
                                                </div>
                                                <div className="content">
                                                    <input
                                                        type="text"
                                                        placeholder="Vermeldingen op de factuur"
                                                        value={this.state.payload.note}
                                                        onChange={(e) =>
                                                            this.handleTextChange(e, 'note')
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">
                                            <h3>{this.state.response.data.questions.extra}</h3>
                                            <div className="content">
                                                <textarea
                                                    placeholder="Vragen/opmerkingen"
                                                    onChange={(e) =>
                                                        this.handleTextChange(e, 'comments')
                                                    }
                                                    value={this.state.payload.comments}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div
                            className={cx('form', {
                                active: this.state.response.status.step === 5,
                            })}>
                            <div className="step">
                                <h2>{this.state.response.data.steps[4].name}</h2>
                                <div className="row">
                                    <p className="large">
                                        {this.state.response.data.steps[4].description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header">
                        <div className="logo"></div>
                    </div>
                    <div className="summary">
                        <div className="steps">
                            <div
                                className={cx('step', {
                                    active: this.state.response.status.step === 1,
                                    done: this.state.response.status.step > 1,
                                })}>
                                <div className="number">
                                    <div className="circle">
                                        <p>1</p>
                                    </div>
                                </div>
                                <div className="info">
                                    <h3>{this.state.response.data.steps[0].name}</h3>
                                    {this.state.response.status.step > 1 && (
                                        <ul>
                                            <li>{this.state.payload.persons} personen</li>
                                            <li>
                                                {this.state.payload.date}{' '}
                                                {formatTime(this.state.payload.start)} -{' '}
                                                {formatTime(this.state.payload.end)}
                                            </li>
                                            <li>
                                                {
                                                    this.state.response.data.events.find(
                                                        (event) =>
                                                            event.id === this.state.payload.event,
                                                    ).name
                                                }
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div
                                className={cx('step', {
                                    active: this.state.response.status.step === 2,
                                    done: this.state.response.status.step > 2,
                                })}>
                                <div className="number">
                                    <div className="circle">
                                        <p>2</p>
                                    </div>
                                </div>
                                <div className="info">
                                    <h3>{this.state.response.data.steps[1].name}</h3>
                                    {this.state.response.status.step > 2 && (
                                        <ul>
                                            {this.state.response.data.spaces
                                                .filter((space) =>
                                                    this.state.payload.spaces.includes(space.id),
                                                )
                                                .map((space, key) => (
                                                    <li key={key}>{space.name}</li>
                                                ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div
                                className={cx('step', {
                                    active: this.state.response.status.step === 3,
                                    done: this.state.response.status.step > 3,
                                })}>
                                <div className="number">
                                    <div className="circle">
                                        <p>3</p>
                                    </div>
                                </div>
                                <div className="info">
                                    <h3>{this.state.response.data.steps[2].name}</h3>
                                    {this.state.response.status.step > 3 &&
                                        this.state.payload.options.length > 0 && (
                                            <ul>
                                                {this.state.payload.options.map((id, key) => (
                                                    <li key={key}>
                                                        {
                                                            this.state.response.data.options.find(
                                                                (option) => option.id === id,
                                                            ).name
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                </div>
                            </div>
                            <div
                                className={cx('step', {
                                    active: this.state.response.status.step === 4,
                                    done: this.state.response.status.step > 4,
                                })}>
                                <div className="number">
                                    <div className="circle">
                                        <p>4</p>
                                    </div>
                                </div>
                                <div className="info">
                                    <h3>{this.state.response.data.steps[3].name}</h3>
                                    {this.state.response.status.step > 4 && (
                                        <ul>
                                            {this.state.payload.business && (
                                                <li>{this.state.payload.company}</li>
                                            )}
                                            <li>
                                                {this.state.payload.firstname}{' '}
                                                {this.state.payload.lastname}
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="price">
                            <div className="row">
                                <div className="item">Subtotaal</div>
                                <div className="item">{formatPrice(price.subtotal)}</div>
                            </div>
                            {this.state.response.status.discount && (
                                <div className="row">
                                    <div className="item">Korting klantcode</div>
                                    <div className="item">{formatPrice(-price.discount)}</div>
                                </div>
                            )}
                            <div className="row">
                                <div className="item">BTW</div>
                                <div className="item">{formatPrice(price.tax)}</div>
                            </div>
                            <div className="row">
                                <div className="item">Totaal</div>
                                <div className="item">{formatPrice(price.total)}</div>
                            </div>
                        </div>
                    </div>
                    <div className={cx('loader', { active: this.state.loading })}>
                        <div className="spinner"></div>
                    </div>
                    <div className="navigation">
                        <div
                            className={cx('btn', {
                                enabled:
                                    this.state.step > 1 &&
                                    this.state.step < 5 &&
                                    !this.state.loading,
                            })}
                            onClick={this.handlePrevious}>
                            Vorige stap
                        </div>
                        <div className="timeline">
                            {this.state.response.data.steps
                                .filter((step) => step.visible)
                                .map((step, key) => (
                                    <div
                                        key={key}
                                        className={cx('tick', {
                                            enabled: key < this.state.response.status.step,
                                        })}>
                                        {key + 1}
                                    </div>
                                ))}
                        </div>
                        <div
                            className={cx('btn', {
                                enabled: this.state.step < 5 && !this.state.loading,
                            })}
                            onClick={this.handleNext}>
                            {this.state.response.status.step === 4 && !!this.state.payload.mode
                                ? this.state.payload.mode === 'send'
                                    ? 'Verstuur'
                                    : 'Download'
                                : 'Volgende stap'}
                        </div>
                    </div>
                </>
            )
        )
    }
}
